import * as React from "react";
import { connect } from "react-redux";
import OneTopic from "./smallComponents/OneTopic";
import "./i18n";
import i18next from "i18next";

const Forum = () => (
  <div>
    <div className="forum">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <br />
            <br />
            <h1 className="text"> {i18next.t("Sve teme na forumu")}</h1>
            <br />
            <br />
            <br />

            <OneTopic />
            <br />
          </div>
          <div className="col-md-4"></div>
        </div>
      </div>
    </div>
  </div>
);

export default connect()(Forum);
