import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Axios from "axios";
import "i18next";
import i18next from "i18next";

class OneTopic extends React.PureComponent {
  state = {
    values: [],
  };

  componentDidMount() {
    Axios.get(
      "https://nutritionchangechallengebackend.com/api/ForumTheme/list/Zdrav zivot"
    ).then((response) => {
      this.setState({
        values: response.data,
      });
    });
  }

  public render() {
    return (
      <React.Fragment>
        <div>
          {this.state.values.map((value: any) => (
            <div>
              <div className="tema">
                <br />
                <br />
                <h2 className="text-forum">{value.title}</h2>
                <p className="text-forum">{value.createDate.slice(0, 10)}</p>
                <p className="text-forum">{value.ownerName}</p>
                <p className="text-forum-saznaj">
                  <Link
                    to={`/topicdetails/${value.id}`}
                    style={{ textDecoration: "none" }}
                    key={value.id}
                  >
                    {i18next.t("Saznaj vise")}
                  </Link>
                </p>
              </div>
              <br />
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(OneTopic);
