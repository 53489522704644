import * as React from "react";
import { connect } from "react-redux";
import "./i18n";
import i18next from "i18next";

interface IStateAppointment {
  date: any;
  time: any;
}

class Appointment extends React.PureComponent<{}, IStateAppointment> {
  constructor(props: {}) {
    super(props);

    this.state = {
      date: "",
      time: "",
    };
  }

  appointmentSubmit(e: any): void {
    e.preventDefault();
    //console.log(this.state);

    var xhr = new XMLHttpRequest();
    var url = "https://nutritionchangechallengebackend.com/api/appointments/";
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader(
      "Authorization",
      "Bearer " + localStorage.getItem("tokenUser")
    );
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        var json = JSON.parse(xhr.responseText);
        //window.location.replace("/profile/");

        let uspesno = document.getElementById(
          "uspesno"
        ) as HTMLParagraphElement;
        uspesno.innerHTML =
          "Uspesno ste poslali predlog termina. Proverite mejl.";
      }
    };

    this.setState({});

    var data = JSON.stringify({
      time: this.state.date + " " + this.state.time,
    });
    //console.log(data);
    xhr.send(data);
  }

  public render() {
    return (
      <React.Fragment>
        <div>
          <div className="online">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-4">
                  <br />
                  <br />
                  <h1 className="text">
                    {i18next.t("Izaberi termin online sastanka")}
                  </h1>
                  <br />
                  <br />
                  <br />
                  <form onSubmit={(e) => this.appointmentSubmit(e)}>
                    <input
                      type="date"
                      id="start"
                      name="trip-start"
                      min="2021-10-01"
                      max="2022-12-31"
                      required
                      onChange={(e) => this.setState({ date: e.target.value })}
                    />
                    <br />
                    <br />
                    <br />
                    <input
                      type="time"
                      id="appt"
                      name="appt"
                      required
                      onChange={(e) => this.setState({ time: e.target.value })}
                    />

                    <br />
                    <br />
                    <br />
                    <p className="text" id="uspesno"></p>
                    <br />
                    <br />
                    <br />
                    <section className="intro">
                      <button
                        id="js-trigger-overlay"
                        className="intro"
                        type="submit"
                      >
                        {i18next.t("Pošalji")}
                      </button>
                    </section>
                  </form>
                </div>
                <div className="col-md-4"></div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(Appointment);
