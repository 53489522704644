import * as React from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import Axios from "axios";
import TemaAdmin from "./TemaAdmin";
import ZadatakAdmin from "./ZadatakAdmin";

interface ITaskAdnim {
  task: string;
}

class TaskAdnim extends React.PureComponent<{}, ITaskAdnim> {
  state = {
    values: [],
    task: "",
  };

  taskSubmit(e: any): void {
    e.preventDefault();

    var xhr = new XMLHttpRequest();
    var url = "https://nutritionchangechallengebackend.com/api/GroupTasks";
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        var json = JSON.parse(xhr.responseText);

        window.location.replace("/adminpanel");
      }
    };

    var data = JSON.stringify({
      task: this.state.task,
    });

    xhr.send(data);

    this.setState({});
  }

  public render() {
    return (
      <React.Fragment>
        <div>
          <div className="prijava-profil">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-4">
                  <br />
                  <br />
                  <h1 className="text ">Zadaci za grupu</h1>
                  <br />
                  <br />
                  <br />
                  <form onSubmit={(e) => this.taskSubmit(e)}>
                    <p className="text">Zadatak: </p>
                    <input
                      type="text"
                      id="task"
                      name="task"
                      required
                      onChange={(e) => this.setState({ task: e.target.value })}
                    />
                    <br />
                    <br />
                    <br />

                    <section className="intro">
                      <button
                        id="js-trigger-overlay"
                        className="intro"
                        type="submit"
                      >
                        Dodaj zadatak
                      </button>
                    </section>
                  </form>
                  <br />
                  <br />
                  <br />
                  <ZadatakAdmin />
                  <br />
                  <br />
                  <br />
                </div>
                <div className="col-md-4"></div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(TaskAdnim);
