import * as React from "react";
import { connect } from "react-redux";
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";

class AdminPanel extends React.PureComponent {
  public render() {
    return (
      <React.Fragment>
        <div>
          <div className="prijava-profil">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-4">
                  <br />
                  <br />
                  <h1 className="text">Admin panel</h1>
                  <br />
                  <br />
                  <br />
                  <NavLink tag={Link} className="nav centerNav" to="/profile">
                    Idi na sajt
                  </NavLink>
                  <NavLink
                    tag={Link}
                    className="nav centerNav"
                    to="/anketeAdmin"
                  >
                    Pogledaj sve ankete
                  </NavLink>
                  <NavLink tag={Link} className="nav centerNav" to="/register">
                    Dodaj korisnika
                  </NavLink>
                  <NavLink
                    tag={Link}
                    className="nav centerNav"
                    to="/forumadmin"
                  >
                    Forum
                  </NavLink>
                  <NavLink
                    tag={Link}
                    className="nav centerNav"
                    to="/tasksAdmin"
                  >
                    Zadaci
                  </NavLink>
                  <NavLink
                    tag={Link}
                    className="nav centerNav"
                    to="/receptiAdmin"
                  >
                    Recepti
                  </NavLink>
                  <NavLink
                    tag={Link}
                    className="nav centerNav"
                    to="/korisniciAdmin"
                  >
                    Korisnici
                  </NavLink>
                  <NavLink
                    tag={Link}
                    className="nav centerNav"
                    to="/terminiAdmin"
                  >
                    Termini
                  </NavLink>

                  <br />
                  <br />
                  <br />
                  <hr />
                  <NavLink
                    tag={Link}
                    className="nav centerNav whiteRangList"
                    to="/noviprogram"
                  >
                    NOVI PROGRAM
                  </NavLink>
                  <hr />
                  <br />
                </div>
                <div className="col-md-4"></div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(AdminPanel);
