import * as React from "react";
import { connect } from "react-redux";
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import profilImage from "../img/profil.png";
import OneRanglistKilos from "./smallComponents/OneRanglistKilos";
import OneRanglistSteps from "./smallComponents/OneRanglistSteps";
import "./i18n";
import i18next from "i18next";

const RangListKilos = () => (
  <div>
    <div className="ranglista">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <br />
            <br />
            <h1 className="text">{i18next.t("Rang list")}</h1>
            <br />
            <ul className="navbar-nav flex-grow">
              <NavLink tag={Link} className="nav center" to="/rangList">
                {i18next.t("Najvise koraka")}
              </NavLink>
              <NavLink
                tag={Link}
                className="nav center whiteRangList"
                to="/ranglistkilos"
              >
                {i18next.t("Najvise izgubljenih kilograma u procentima")}
              </NavLink>
              <NavLink tag={Link} className="nav center" to="/ranglistdeeds">
                {i18next.t("Najviše dobrih stvari")}
              </NavLink>
              <NavLink tag={Link} className="nav center" to="/ranglistkm">
                {i18next.t("Najviše predjenih kilometara")}
              </NavLink>
            </ul>
            <OneRanglistKilos />

            <br />
            <br />
          </div>
          <div className="col-md-4"></div>
        </div>
      </div>
    </div>
  </div>
);

export default connect()(RangListKilos);
