import * as React from "react";
import { connect } from "react-redux";

const ForumComment = () => (
  <div>
    <div className="forumdetaljinje">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <br />
            <br />
            <h1 className="text">Izabrana tema</h1>
            <br />
            <br />
            <br />
            <div className="tema">
              <p className="text-forum">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed
                fringilla metus.Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Sed sed fringilla metus.
              </p>
              <p className="text-forum-saznaj">Autor</p>
            </div>
            <br />
            <br />
            <div className="tema">
              <p className="text-forum">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed
                fringilla metus.Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Sed sed fringilla metus.
              </p>
              <p className="text-forum-saznaj">Autor</p>
            </div>
            <br />
            <br />
            <div className="tema">
              <p className="text-forum">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed
                fringilla metus.Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Sed sed fringilla metus.
              </p>
              <p className="text-forum-saznaj">Autor</p>
            </div>

            <br />
            <br />
            <textarea id="" name="">
              Upisi komentar
            </textarea>
            <br />
            <br />
            <section className="intro">
              <button id="js-trigger-overlay" className="intro" type="button">
                <a href="forum.html">Posalji</a>
              </button>
            </section>
            <br />
            <br />
            <br />
            <br />
          </div>
          <div className="col-md-4"></div>
        </div>
      </div>
    </div>
  </div>
);

export default connect()(ForumComment);
