import * as React from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import Axios from "axios";

interface IUserDetails {
  id: any;
  fullName: any;
  nickName: any;
  email: any;
  profileImgPath: any;
  weight: any;
  waistSize: any;
  isInstructor: any;
  token: any;
}

interface UserDetailsProps extends RouteComponentProps<IUserDetails> {}

class UserDetails extends React.PureComponent<UserDetailsProps> {
  newid = this.props.match.params.id;

  state = {
    values: [],
    veluesSteps: [],
    veluesProgress: [],
    id: "",
    fullName: "",
    nickName: "",
    email: "",
    profileImgPath: "",
    weight: "",
    waistSize: "",
    isInstructor: "",
    token: "",
  };

  async componentDidMount() {
    console.log(this.newid);
    Axios.get<any>(
      "https://nutritionchangechallengebackend.com/api/users/" + this.newid
    )
      .then((response): any => {
        this.setState({
          values: response.data,
          fullName: response.data["fullName"],
          nickName: response.data["nickName"],
          email: response.data["email"],
          profileImgPath: response.data["profileImgPath"],
          weight: response.data["weight"],
        });
        console.log(response.data["profileImgPath"]);
      })
      .then(
        await Axios.get<any>(
          "https://nutritionchangechallengebackend.com/api/measures/userStepsImages/" +
            this.newid
        ).then((response): any => {
          this.setState({
            veluesSteps: response.data,
          });
        })
      )
      .then(
        await Axios.get<any>(
          "https://nutritionchangechallengebackend.com/api/measures/userProgressImages/" +
            this.newid
        ).then((response): any => {
          this.setState({
            veluesProgress: response.data,
          });
        })
      );
  }

  public render() {
    return (
      <React.Fragment>
        <div className="forumdetaljinje prijava-profil">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <br />
                <br />
                <h1 className="text">Profil korisnika</h1>
                <br />
                <br />
                <br />
                <div>
                  <div className="tema">
                    <div className="text-forum centerProfile">
                      <img
                        src={this.state.profileImgPath}
                        width="150"
                        height="150"
                      />
                      <br />
                      <br />
                      <h1>
                        <span id="korisnikSpan">
                          {this.props.match.params.fullName}
                        </span>
                      </h1>
                      <p>
                        Korisničko ime:&nbsp;
                        <span id="korisnikSpan">
                          {this.props.match.params.nickName}
                        </span>
                      </p>
                      <p>
                        Email:&nbsp;
                        <span id="korisnikSpan">
                          {this.props.match.params.email}
                        </span>
                      </p>
                      <p>
                        Trenutna kilaža:&nbsp;
                        <span id="korisnikSpan">
                          {this.props.match.params.weight}
                        </span>
                        kg
                      </p>
                    </div>
                  </div>
                  <br />
                </div>
                <br />
                <br />
                <br />
                <br />
              </div>
              <div className="col-md-4"></div>
            </div>
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <h3 className="text">Slike koraka</h3>
              </div>
              <div className="col-md-4"></div>
            </div>
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                {this.state.veluesSteps.map((veluesStep: any) => (
                  <img
                    className="paad"
                    src={veluesStep.imagePath}
                    width="250"
                    height="250"
                    key={veluesStep.id}
                  />
                ))}
              </div>
              <div className="col-md-4"></div>
            </div>
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <h3 className="text">Slike progresa</h3>
              </div>
              <div className="col-md-4"></div>
            </div>
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                {this.state.veluesProgress.map((veluesProgres: any) => (
                  <img
                    className="paad"
                    src={veluesProgres.imagePath}
                    width="250"
                    height="250"
                    key={veluesProgres.id}
                  />
                ))}
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(UserDetails);
