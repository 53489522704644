import * as React from "react";
import { connect } from "react-redux";
import "./i18n";
import i18next from "i18next";

const Rules = () => (
  <div>
    <div className="pravila">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <br />
            <br />
            <h1 className="intro text">Change challenge</h1>
            <br />
            <br />
            <p className="intro text">
              {i18next.t("Hvala Vam na interesovanju")}
            </p>
            <p className="intro text">
              {i18next.t("Najcesci razlozi zbog")}
            </p>
            <p className="intro text">
              {i18next.t("Challenge traje 4 nedelje")}
            </p>
            <p className="intro text">
              {i18next.t("Nakon")}
            </p>
            <p className="intro text"> {i18next.t("Radujemo")}</p>
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
    </div>
  </div>
);

export default connect()(Rules);
