import * as React from "react";
import { connect } from "react-redux";
import OneTask from "./smallComponents/OneTask";
import "./i18n";
import i18next from "i18next";

const Tasks = () => (
  <div>
    <div className="zadaci">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2"></div>

          <div className="col-md-8">
            <br />
            <br />
            <h1 className="text">{i18next.t("Tasks")}</h1>
            <br />
            <OneTask />
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </div>
  </div>
);

export default connect()(Tasks);
