import * as React from "react";
import { connect } from "react-redux";
import profilImage from "../../img/profil.png";
import Axios from "axios";
import { Link } from "react-router-dom";

class OneRanglistDeeds extends React.PureComponent {
  state = {
    values: [],
  };

  componentDidMount() {
    Axios.get(
      "https://nutritionchangechallengebackend.com/api/Ranglist/deeds-program/"
    ).then((response) => {
      this.setState({ values: response.data });
    });
  }

  public render() {
    return (
      <React.Fragment>
        <div>
          <div className="jedanrecept">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  {this.state.values.map((value: any, i: number) => (
                    <div>
                      <br />
                      <br />
                      <div className="row lista-jedan">
                        <div className="col-md-1" key={i}>
                          <h2>{i + 1}. </h2>
                        </div>
                        <div className="col-md-7">
                          <h2>{value.userName}</h2>
                          <br />
                          <h1>{Math.round(value.value * 100) / 100} </h1>
                        </div>
                      </div>
                    </div>
                  ))}
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default connect()(OneRanglistDeeds);
