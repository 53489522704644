import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import Axios from "axios";
import { Link } from "react-router-dom";

interface IAnketa {
  id: any;
  fullname: any;
  city: any;
  address: any;
  birth: any;
  number: any;
  motive: any;
  obligations: any;
  sweets: any;
  diet: any;
  difficulty: any;
  medicines: any;
  opinion: any;
  mistakes: any;
  weight: any;
  height: any;
  magicNumber: any;
  training: any;
  measurement: any;
  questions: any;
  state: any;
}

interface AnketaProps extends RouteComponentProps<IAnketa> {}

class AnketaDetaljnije extends React.PureComponent<AnketaProps> {
  newid = this.props.match.params.id;

  state = {
    values: [],
  };

  componentDidMount() {
    console.log(this.newid);
    Axios.get(
      "https://nutritionchangechallengebackend.com/api/surveys/" + this.newid
    ).then((response) => {
      this.setState({
        values: response.data,
      });
    });
  }

  public render() {
    return (
      <React.Fragment>
        <div>
          <div className="prijava-profil">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-4">
                  <br />
                  <br />
                  <h1 className="text">{this.props.match.params.fullname} </h1>
                  <br />
                  <p className="text leftAnketa">
                    <span className="pinkspan">Grad:&nbsp;</span>
                    {this.props.match.params.city}
                  </p>
                  <p className="text leftAnketa">
                    <span className="pinkspan">Adresa:&nbsp;</span>
                    {this.props.match.params.address}
                  </p>
                  <p className="text leftAnketa">
                    <span className="pinkspan">Datum rođenja:&nbsp;</span>
                    {this.props.match.params.birth}
                  </p>
                  <p className="text leftAnketa">
                    <span className="pinkspan">Broj:&nbsp;</span>
                    {this.props.match.params.number}
                  </p>
                  <p className="text leftAnketa">
                    <span className="pinkspan">Motiv:&nbsp;</span>
                    {this.props.match.params.motive}
                  </p>
                  <p className="text leftAnketa">
                    <span className="pinkspan">Obaveze:&nbsp;</span>
                    {this.props.match.params.obligations}
                  </p>
                  <p className="text leftAnketa">
                    <span className="pinkspan">Slatkiši:&nbsp;</span>
                    {this.props.match.params.sweets}
                  </p>
                  <p className="text leftAnketa">
                    <span className="pinkspan">Dijeta:&nbsp;</span>
                    {this.props.match.params.diet}
                  </p>
                  <p className="text leftAnketa">
                    <span className="pinkspan">Poteškoće:&nbsp;</span>
                    {this.props.match.params.difficulty}
                  </p>
                  <p className="text leftAnketa">
                    <span className="pinkspan">Lekovi:&nbsp;</span>
                    {this.props.match.params.medicines}
                  </p>
                  <p className="text leftAnketa">
                    <span className="pinkspan">Mišljenje:&nbsp;</span>
                    {this.props.match.params.opinion}
                  </p>
                  <p className="text leftAnketa">
                    <span className="pinkspan">Greške:&nbsp;</span>
                    {this.props.match.params.mistakes}
                  </p>
                  <p className="text leftAnketa">
                    <span className="pinkspan">Težina:&nbsp;</span>
                    {this.props.match.params.weight}
                  </p>
                  <p className="text leftAnketa">
                    <span className="pinkspan">Visina:&nbsp;</span>
                    {this.props.match.params.height}
                  </p>
                  <p className="text leftAnketa">
                    <span className="pinkspan">Magični broj:&nbsp;</span>
                    {this.props.match.params.magicNumber}
                  </p>
                  <p className="text leftAnketa">
                    <span className="pinkspan">Trening:&nbsp;</span>
                    {this.props.match.params.training}
                  </p>
                  <p className="text leftAnketa">
                    <span className="pinkspan">Mere:&nbsp;</span>
                    {this.props.match.params.measurement}
                  </p>
                  <p className="text leftAnketa">
                    <span className="pinkspan">Pitanja:&nbsp;</span>
                    {this.props.match.params.questions}
                  </p>
                  <p className="text leftAnketa">
                    <span className="pinkspan">Status:&nbsp;</span>
                    {this.props.match.params.state}
                  </p>
                  <br />
                  <br />

                  <br />
                </div>
                <div className="col-md-4"></div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(AnketaDetaljnije);
