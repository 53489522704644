import * as React from "react";
import { Route } from "react-router";
import Layout from "./components/Layout";
import Home from "./components/Home";
import Counter from "./components/Counter";
import FetchData from "./components/FetchData";
import Profile from "./components/Profile";
import RangList from "./components/RangListSteps";
import Tasks from "./components/Tasks";
import Appointment from "./components/Appointment";
import Rules from "./components/Rules";
import Forum from "./components/Forum";
import ForumComment from "./components/ForumComment";
import Recepies from "./components/Recepies";
import Recipe from "./components/smallComponents/OneRecipe";
import Login from "./components/Login";
import Questionnaire from "./components/Questionnaire";

import Register from "./components/admin/Register";
import AdminPanel from "./components/admin/AdminPanel";

import "./custom.css";
import OneTask from "./components/smallComponents/OneTask";
import TopicDetails from "./components/smallComponents/TopicDetails";
import OneTopic from "./components/smallComponents/OneTopic";
import RecipeDetails from "./components/smallComponents/RecipeDetails";
import OneBreakfast from "./components/smallComponents/OneBreakfast";
import Breakfast from "./components/Breakfast";
import Lunch from "./components/Lunch";
import OneLunch from "./components/smallComponents/OneLunch";
import Drink from "./components/Drink";
import OneDrink from "./components/smallComponents/OneDrink";
import Dinner from "./components/Dinner";
import OneDinner from "./components/smallComponents/OneDinner";
import RanglistKilos from "./components/RangListKilos";
import RangListDeeds from "./components/RangListDeeds";
import RangListKm from "./components/RangListKm";
import ForumAdmin from "./components/admin/ForumAdmin";
import AnketeAdmin from "./components/admin/AnketeAdmin";
import TasksAdmin from "./components/admin/TasksAdmin";
import ReceptiAdmin from "./components/admin/ReceptiAdmin";
import KorisniciAdmin from "./components/admin/KorisniciAdmin";
import TerminiAdmin from "./components/admin/TerminiAdmin";
import AnketaDetaljnije from "./components/admin/AnketaDetaljnije";
import UserDetails from "./components/admin/UserDetails";
import AdminNoviProgram from "./components/admin/AdminNoviProgram";
import GroupChat from "./components/GroupChat";

export default () => (
  <Layout>
    <Route exact path="/" component={Home} />
    <Route path="/counter" component={Counter} />
    <Route path="/fetch-data/:startDateIndex?" component={FetchData} />
    <Route path="/profile" component={Profile} />
    <Route path="/rangList" component={RangList} />
    <Route path="/ranglistkilos" component={RanglistKilos} />
    <Route path="/ranglistkm" component={RangListKm} />
    <Route path="/ranglistdeeds" component={RangListDeeds} />
    <Route path="/tasks" component={Tasks} />
    <Route path="/appointment" component={Appointment} />
    <Route path="/rules" component={Rules} />
    <Route path="/forum" component={Forum} />
    <Route path="/forumadmin" component={ForumAdmin} />
    <Route path="/anketeAdmin" component={AnketeAdmin} />
    <Route path="/tasksAdmin" component={TasksAdmin} />
    <Route path="/receptiAdmin" component={ReceptiAdmin} />
    <Route path="/korisniciAdmin" component={KorisniciAdmin} />
    <Route path="/terminiAdmin" component={TerminiAdmin} />
    <Route path="/forumComment" component={ForumComment} />
    <Route path="/recepies" component={Recepies} />
    <Route path="/recipe" component={Recipe} />
    <Route path="/login" component={Login} />
    <Route path="/questionnaire" component={Questionnaire} />
    <Route path="/register" component={Register} />
    <Route path="/adminpanel" component={AdminPanel} />
    <Route path="/onetask" component={OneTask} />
    <Route path="/onetopic" component={OneTopic} />
    <Route path="/topicdetails/:id" component={TopicDetails} />
    <Route path="/breakfast" component={Breakfast} />
    <Route path="/onebreakfast" component={OneBreakfast} />
    <Route path="/lunch" component={Lunch} />
    <Route path="/onelunch" component={OneLunch} />
    <Route path="/dinner" component={Dinner} />
    <Route path="/onediner" component={OneDinner} />
    <Route path="/drinks" component={Drink} />
    <Route path="/onedrink" component={OneDrink} />
    <Route
      path="/anketadetaljnije/:id/:fullname/:city/:address/:birth/:number/:motive/:obligations/:sweets/:diet/:difficulty/:medicines/:opinion/:mistakes/:weight/:height/:magicNumber/:training/:measurement/:questions/:state"
      component={AnketaDetaljnije}
    />
    <Route path="/noviprogram" component={AdminNoviProgram} />
    <Route
      path="/recipedetails/:id/:title/:ingredients/:description/:category/:timeToPrepare"
      component={RecipeDetails}
    />
    <Route
      path="/userdetails/:id/:fullName/:nickName/:email/:weight"
      component={UserDetails}
    />
    <Route path="/groupchat" component={GroupChat} />
  </Layout>
);
