import * as React from "react";
import { connect } from "react-redux";
import "./i18n";
import i18next from "i18next";

interface IStateQuestionnaire {
  fullname: string;
  city: string;
  address: string;
  birth: string;
  number: string;
  motive: string;
  obligations: string;
  sweets: string;
  diet: string;
  difficulty: string;
  medicines: string;
  opinion: string;
  mistakes: string;
  weight: string;
  height: string;
  magicNumber: string;
  training: string;
  measurement: string;
  questions: string;
}

class Questionnaire extends React.PureComponent<{}, IStateQuestionnaire> {
  constructor(props: {}) {
    super(props);

    this.state = {
      fullname: "",
      city: "",
      address: "",
      birth: "",
      number: "",
      motive: "",
      obligations: "",
      sweets: "",
      diet: "",
      difficulty: "",
      medicines: "",
      opinion: "",
      mistakes: "",
      weight: "",
      height: "",
      magicNumber: "",
      training: "",
      measurement: "",
      questions: "",
    };
  }

  questionnaireSubmit(e: any): void {
    e.preventDefault();

    var xhr = new XMLHttpRequest();
    var url = "https://nutritionchangechallengebackend.com/api/surveys";
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        var json = JSON.parse(xhr.responseText);
        window.location.replace("/");
      }
    };

    var data = JSON.stringify({
      fullname: this.state.fullname,
      city: this.state.city,
      address: this.state.address,
      birth: this.state.birth,
      number: this.state.number,
      motive: this.state.motive,
      obligations: this.state.obligations,
      sweets: this.state.sweets,
      diet: this.state.diet,
      difficulty: this.state.difficulty,
      medicines: this.state.medicines,
      opinion: this.state.opinion,
      mistakes: this.state.mistakes,
      weight: this.state.weight,
      height: this.state.height,
      magicNumber: this.state.magicNumber,
      training: this.state.training,
      measurement: this.state.measurement,
      questions: this.state.questions,
    });
    xhr.send(data);

    this.setState({});
  }

  onChangeLang = (option: any) => {
    localStorage.setItem("lang", option);
    window.location.reload();
  };

  public render() {
    const lang = localStorage.getItem("lang") || "ger";
    i18next.changeLanguage(lang);

    return (
      <React.Fragment>
        <div className="prijava">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4">
                <img
                  className="flagPocetna"
                  src={"img/german.png"}
                  width="25"
                  height="25"
                  onClick={() => this.onChangeLang("ger")}
                />
                <img
                  className="flagPocetna"
                  src={"img/eng.png"}
                  width="25"
                  height="25"
                  onClick={() => this.onChangeLang("eng")}
                />
                <img
                  className="flagPocetna"
                  src={"img/serbia.png"}
                  width="25"
                  height="25"
                  onClick={() => this.onChangeLang("ser")}
                />
              </div>
              <div className="col-md-4">
                <br />
                <br />
                <h1 className="text ">
                  <p className="text">{i18next.t("Prijava")}</p>
                </h1>
                <br />
                <br />
                <br />
                <br />
                <br />
                <form onSubmit={(e) => this.questionnaireSubmit(e)}>
                  <p className="text">{i18next.t("Vaše ime i prezime*")}</p>
                  <input
                    type="text"
                    id="fullname"
                    name="fullname"
                    required
                    onChange={(e) =>
                      this.setState({ fullname: e.target.value })
                    }
                  />
                  <br />
                  <br />
                  <br />
                  <p className="text">
                    {i18next.t("Gde stanujete, u kom gradu?*")}
                  </p>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    required
                    onChange={(e) => this.setState({ city: e.target.value })}
                  />
                  <br />
                  <br />
                  <br />
                  <p className="text"> {i18next.t("Vaša tačna adresa je*")}</p>
                  <input
                    type="text"
                    id="address"
                    name="address"
                    required
                    onChange={(e) => this.setState({ address: e.target.value })}
                  />
                  <br />
                  <br />
                  <br />
                  <p className="text">{i18next.t("Vaš datum rođenja*")}</p>
                  <input
                    type="text"
                    id="birth"
                    name="birth"
                    required
                    onChange={(e) => this.setState({ birth: e.target.value })}
                  />
                  <br />
                  <br />
                  <br />
                  <p className="text">
                    {i18next.t(
                      "Vaš broj telefona na koji želite da primate obaveštenja*"
                    )}
                  </p>
                  <input
                    type="text"
                    id="number"
                    name="number"
                    required
                    onChange={(e) => this.setState({ number: e.target.value })}
                  />
                  <br />
                  <br />
                  <br />
                  <p className="text">
                    {i18next.t("Zašto ste se prijavili za ovaj izazov?*")}
                  </p>
                  <input
                    type="text"
                    id="motive"
                    name="motive"
                    required
                    onChange={(e) => this.setState({ motive: e.target.value })}
                  />
                  <br />
                  <br />
                  <br />
                  <p className="text">
                    {i18next.t(
                      "Da li i kada imate obaveze radnim danima-posao, fakultet i sl? Odgovor treba da bude u formi, radim od 7-15h npr. ili slobodna sam ceo dan...ili već kako je to kod Vas.*"
                    )}
                  </p>
                  <input
                    type="text"
                    id="obligations"
                    name="obligations"
                    required
                    onChange={(e) =>
                      this.setState({ obligations: e.target.value })
                    }
                  />
                  <br />
                  <br />
                  <br />
                  <p className="text">
                    {i18next.t("Da li ste danas jeli neki slatkiš?*")}
                  </p>
                  <input
                    type="text"
                    id="sweets"
                    name="sweets"
                    required
                    onChange={(e) => this.setState({ sweets: e.target.value })}
                  />
                  <br />
                  <br />
                  <br />
                  <p className="text">
                    {i18next.t(
                      "Da li ste već na nekom režimu ishrane-dijeti i sl?*"
                    )}
                  </p>
                  <input
                    type="text"
                    id="diet"
                    name="diet"
                    required
                    onChange={(e) => this.setState({ diet: e.target.value })}
                  />
                  <br />
                  <br />
                  <br />
                  <p className="text">
                    {i18next.t("Šta će Vam po Vašem mišljenju biti najteže?*")}
                  </p>
                  <input
                    type="text"
                    id="difficulty"
                    name="difficulty"
                    required
                    onChange={(e) =>
                      this.setState({ difficulty: e.target.value })
                    }
                  />
                  <br />
                  <br />
                  <br />
                  <p className="text">
                    {i18next.t(
                      "Da li pijete neke lekove? Ako pijete neke lekove napišite mi koji su, koje doze i za šta ih koristite?*"
                    )}
                  </p>
                  <input
                    type="text"
                    id="medicines"
                    name="medicines"
                    required
                    onChange={(e) =>
                      this.setState({ medicines: e.target.value })
                    }
                  />
                  <br />
                  <br />
                  <br />
                  <p className="text">
                    {i18next.t(
                      "Po Vašem mišljenju koliko će Vam biti teško da ispoštujete pravila?*"
                    )}
                  </p>
                  <input
                    type="text"
                    id="opinion"
                    name="opinion"
                    required
                    onChange={(e) => this.setState({ opinion: e.target.value })}
                  />
                  <br />
                  <br />
                  <br />
                  <p className="text">
                    {i18next.t("Šta mislite da ste do sada pogrešno radili")}
                  </p>
                  <input
                    type="text"
                    id="mistakes"
                    name="mistakes"
                    required
                    onChange={(e) =>
                      this.setState({ mistakes: e.target.value })
                    }
                  />
                  <br />
                  <br />
                  <br />
                  <p className="text">
                    {i18next.t("Da li znate koliko imate trenutno kg?*")}
                  </p>
                  <input
                    type="text"
                    id="weight"
                    name="weight"
                    required
                    onChange={(e) => this.setState({ weight: e.target.value })}
                  />
                  <br />
                  <br />
                  <br />
                  <p className="text">
                    {i18next.t(
                      "Upišite koliko ste visoki i koliko imate kg, ako ne znate baš tačno i nemate nikako priliku da se izmerite napišite bar okvirno...*"
                    )}
                  </p>
                  <input
                    type="text"
                    id="height"
                    name="height"
                    required
                    onChange={(e) => this.setState({ height: e.target.value })}
                  />
                  <br />
                  <br />
                  <br />
                  <p className="text">
                    {i18next.t(
                      "Koliko kg biste voleli da imate da imate čaroban štapić i da je moguće da birate?*"
                    )}
                  </p>
                  <input
                    type="text"
                    id="magicNumber"
                    name="magicNumber"
                    required
                    onChange={(e) =>
                      this.setState({ magicNumber: e.target.value })
                    }
                  />
                  <br />
                  <br />
                  <br />
                  <p className="text">
                    {i18next.t(
                      "Da li nešto trenirate, šta i koliko puta nedeljno? Ako ništa ne trenirate napišite to kao i koliko dugo ne trenirate...*"
                    )}
                  </p>
                  <input
                    type="text"
                    id="training"
                    name="training"
                    required
                    onChange={(e) =>
                      this.setState({ training: e.target.value })
                    }
                  />
                  <br />
                  <br />
                  <br />
                  <p className="text">
                    {i18next.t("Koliko često se merite?*")}
                  </p>
                  <input
                    type="text"
                    id="measurement"
                    name="measurement"
                    required
                    onChange={(e) =>
                      this.setState({ measurement: e.target.value })
                    }
                  />
                  <br />
                  <br />
                  <br />
                  <p className="text">
                    {i18next.t(
                      "Da li imate neko pitanje, postavite ga, rado ćemo odgovoriti i poslati Vam odgovor...*"
                    )}
                  </p>
                  <input
                    type="text"
                    id="questions"
                    name="questions"
                    required
                    onChange={(e) =>
                      this.setState({ questions: e.target.value })
                    }
                  />
                  <br />
                  <br />
                  <br />

                  <section className="intro">
                    <button
                      id="js-trigger-overlay"
                      className="intro"
                      type="submit"
                    >
                      {i18next.t("Pošalji")}
                    </button>
                  </section>
                </form>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(Questionnaire);
