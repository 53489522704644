import * as React from "react";
import { connect } from "react-redux";

interface IStateRegister {
  fullName: string;
  nickName: string;
  email: string;
  password: string;
  weight: any;
  waistSize: any;
}

class Register extends React.PureComponent<{}, IStateRegister> {
  constructor(props: {}) {
    super(props);

    this.state = {
      fullName: "",
      nickName: "",
      email: "",
      password: "",
      weight: "",
      waistSize: "",
    };
  }

  registerSubmit(e: any): void {
    e.preventDefault();

    var errorp = document.getElementById("error") as HTMLParagraphElement;

    var xhr = new XMLHttpRequest();
    var url = "https://nutritionchangechallengebackend.com/api/Users/register";
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        var json = JSON.parse(xhr.responseText);

        window.location.replace("/adminpanel");
      }
    };

    var data = JSON.stringify({
      fullName: this.state.fullName,
      nickName: this.state.nickName,
      email: this.state.email,
      password: this.state.password,
      weight: this.state.weight,
      waistSize: this.state.waistSize,
    });

    xhr.send(data);

    this.setState({});
  }

  public render() {
    return (
      <React.Fragment>
        <div>
          <div className="prijava-profil">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-4">
                  <br />
                  <br />
                  <h1 className="text ">Registruj novog člana</h1>
                  <br />
                  <br />
                  <br />
                  <form onSubmit={(e) => this.registerSubmit(e)}>
                    <p className="text">Puno ime i prezime</p>
                    <input
                      type="text"
                      id="fullName"
                      name="fullName"
                      required
                      onChange={(e) =>
                        this.setState({ fullName: e.target.value })
                      }
                    />
                    <br />
                    <br />
                    <br />
                    <p className="text">Korisničko ime </p>
                    <input
                      type="text"
                      id="nick"
                      name="nick"
                      required
                      onChange={(e) =>
                        this.setState({ nickName: e.target.value })
                      }
                    />
                    <br />
                    <br />
                    <br />
                    <p className="text">Email</p>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      required
                      onChange={(e) => this.setState({ email: e.target.value })}
                    />
                    <br />
                    <br />
                    <br />
                    <p className="text">Šifra</p>
                    <input
                      type="text"
                      id="password"
                      name="password"
                      required
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                    />
                    <p className="text">
                      Šifra mora da sadrži najmanje 7 karaktera (velika slova,
                      mala slova, brojeve i specijalne karaktere). Npr:
                      LEPdan#2!
                    </p>
                    <br />
                    <p className="text">Tezina u kilogramima</p>
                    <input
                      type="text"
                      id="password"
                      name="password"
                      required
                      onChange={(e) =>
                        this.setState({ weight: e.target.value })
                      }
                    />
                    <br />
                    <br />
                    <br />
                    <p className="text">Obima struka u centimetrima</p>
                    <input
                      type="text"
                      id="password"
                      name="password"
                      required
                      onChange={(e) =>
                        this.setState({ waistSize: e.target.value })
                      }
                    />
                    <br />
                    <br />
                    <p id="error"> </p>
                    <section className="intro">
                      <button
                        id="js-trigger-overlay"
                        className="intro"
                        type="submit"
                      >
                        Dodaj korisnika
                      </button>
                    </section>
                  </form>
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
                <div className="col-md-4"></div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(Register);
