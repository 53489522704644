import * as React from "react";
import { connect } from "react-redux";

import { Link, RouteComponentProps } from "react-router-dom";
import Axios from "axios";
import "./i18n";
import i18next from "i18next";

interface IProfile {
  id: any;
  numberOfSteps: any;
  weight: any;
  WaistSize: any;
  goodDeed1: any;
  goodDeed2: any;
  goodDeed3: any;
  goodDeed4: any;
  goodDeed5: any;
  weightAtStart: string;
  weistSizeAtStart: string;
  wantedWeight: any;
  avgStepsPerDay: any;
  allStepsOnProgram: any;
  lostCm: any;
  lostWeight: any;
  ProfileImgPath: any;
  file: any;
  image: any;
  selectedFile: any;
  prifile: any;
  fileKoraka: any;
  fileProgresa: any;
}

interface ProfileProps extends RouteComponentProps<IProfile> {}

class Profile extends React.PureComponent<ProfileProps> {
  state = {
    numberOfSteps: 0,
    weight: 0,
    WaistSize: 0,
    goodDeed1: "",
    goodDeed2: "",
    goodDeed3: "",
    goodDeed4: "",
    goodDeed5: "",
    weightAtStart: "",
    weistSizeAtStart: "",
    wantedWeight: "",
    avgStepsPerDay: "",
    allStepsOnProgram: "",
    lostCm: "",
    lostWeight: "",
    ProfileImgPath: localStorage.getItem("profileImgPathUser"),
    file: "",
    selectedFile: null,
    prifile: "",
    fileKoraka: "",
    fileProgresa: "",
  };

  componentDidMount() {
    Axios.get<any>(
      "https://nutritionchangechallengebackend.com/api/Users/OnProgram",
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("tokenUser"),
        },
      }
    ).then((response) => {
      this.setState({
        values: response.data,
        weightAtStart: response.data["weightAtStart"],
        weistSizeAtStart: response.data["weistSizeAtStart"],
        wantedWeight: response.data["wantedWeight"],
        avgStepsPerDay: response.data["avgStepsPerDay"],
        allStepsOnProgram: response.data["allStepsOnProgram"],
        lostCm: response.data["lostCm"],
        lostWeight: response.data["lostWeight"],
        ProfileImgPath: response.data["ProfileImgPath"],
      });
    });
  }

  daysSubmit(e: any): void {
    e.preventDefault();

    var xhr = new XMLHttpRequest();
    var url = "https://nutritionchangechallengebackend.com/api/measures/";
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader(
      "Authorization",
      "Bearer " + localStorage.getItem("tokenUser")
    );
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        var json = JSON.parse(xhr.responseText);
        window.location.replace("/tasks");
      }
    };

    this.setState({});

    var data = JSON.stringify({
      numberOfSteps: this.state.numberOfSteps,
      weight: this.state.weight,
      WaistSize: this.state.WaistSize,
      goodDeed1: this.state.goodDeed1,
      goodDeed2: this.state.goodDeed2,
      goodDeed3: this.state.goodDeed3,
      goodDeed4: this.state.goodDeed4,
      goodDeed5: this.state.goodDeed5,
    });

    xhr.send(data);
  }

  handleFile(e: any): void {
    console.log(e.target.files, "$$$$");
    console.log(e.target.files[0], "$$$$");

    let file = e.target.files[0];
    this.setState({
      file: file,
    });
  }

  handleFileKoraka(e: any): void {
    console.log(e.target.files, "$$$$");
    console.log(e.target.files[0], "$$$$");

    let fileKoraka = e.target.files[0];
    this.setState({
      fileKoraka: fileKoraka,
    });
  }

  handleFileProgresa(e: any): void {
    console.log(e.target.files, "$$$$");
    console.log(e.target.files[0], "$$$$");

    let fileProgresa = e.target.files[0];
    this.setState({
      fileProgresa: fileProgresa,
    });
  }

  fileUploadHandler = () => {
    let file = this.state.file;
    let formdata = new FormData();

    formdata.append("Image", file);

    Axios({
      url: "https://nutritionchangechallengebackend.com/api/Users/profileImage",
      method: "PUT",
      headers: {
        authorization: "Bearer " + localStorage.getItem("tokenUser"),
      },
      data: formdata,
    }).then((res) => {
      Axios({
        url: "https://nutritionchangechallengebackend.com/api/Users",
        method: "GET",
        headers: {
          authorization: "Bearer " + localStorage.getItem("tokenUser"),
        },
      }).then((response: any) => {
        localStorage.setItem(
          "profileImgPathUser",
          response.data.profileImgPath
        );
        window.location.replace("/profile");
      });
    });
  };

  fileUploadHandlerKoraka = () => {
    let fileKoraka = this.state.fileKoraka;
    let formdataKoraka = new FormData();

    formdataKoraka.append("Image", fileKoraka);

    Axios({
      url: "https://nutritionchangechallengebackend.com/api/measures/proofimage",
      method: "PUT",
      headers: {
        authorization: "Bearer " + localStorage.getItem("tokenUser"),
      },
      data: formdataKoraka,
    }).then((res) => {
      window.location.replace("/tasks");
    });
  };

  fileUploadHandlerProgresa = () => {
    let fileProgresa = this.state.fileProgresa;
    let formdataProgresa = new FormData();

    formdataProgresa.append("Image", fileProgresa);

    Axios({
      url: "https://nutritionchangechallengebackend.com/api/measures/progressImage",
      method: "PUT",
      headers: {
        authorization: "Bearer " + localStorage.getItem("tokenUser"),
      },
      data: formdataProgresa,
    }).then((res) => {
      window.location.replace("/tasks");
    });
  };

  onChangeLang = (option: any) => {
    localStorage.setItem("lang", option);
    window.location.reload();
  };

  public render() {
    const lang = localStorage.getItem("lang") || "ger";
    i18next.changeLanguage(lang);

    return (
      <React.Fragment>
        <div>
          <div className="prijava-profil-posle-logovanja">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-1"></div>

                <div className="col-md-2">
                  <br />
                  <p className="text-left">
                    <span className="whitespan">
                      {localStorage.getItem("fullNameUser")}
                    </span>
                  </p>

                  <img
                    className=""
                    src={"" + localStorage.getItem("profileImgPathUser")}
                    width="150"
                    height="150"
                  />

                  <br />
                  <br />
                  <input
                    type="file"
                    name="file"
                    className="profileBtn"
                    onChange={(e) => this.handleFile(e)}
                  />
                  <br />
                  <br />
                  <button
                    onClick={this.fileUploadHandler}
                    className="profileBtn"
                  >
                    {i18next.t("Objavi sliku")}
                  </button>

                  <p className="text-left"></p>
                  <br />
                  <p className="text-left">
                    {i18next.t("Kilaža pre početka u kilogramima:")}
                    <span className="whitespan">
                      {this.state.weightAtStart}
                    </span>
                  </p>
                  <p className="text-left">
                    {i18next.t("Obim struka pre početka u centimetrima:")}
                    <span className="whitespan">
                      {this.state.weistSizeAtStart}
                    </span>
                  </p>
                  <p className="text-left">
                    {i18next.t("Željena kilaža u kilogramima:")}
                    <span className="whitespan">{this.state.wantedWeight}</span>
                  </p>
                  <p className="text-left">
                    {i18next.t("Prosečan broj koraka dnevno:")}
                    <span className="whitespan">
                      {this.state.avgStepsPerDay}
                    </span>
                  </p>
                  <p className="text-left">
                    {i18next.t("Ukupan broj koraka:")}
                    <span className="whitespan">
                      {this.state.allStepsOnProgram}
                    </span>
                  </p>
                  <p className="text-left">
                    {i18next.t("Izgubljeni centimetri:")}
                    <span className="whitespan">{this.state.lostCm}</span>
                  </p>
                  <p className="text-left">
                    {i18next.t("Izgubljeni kilogrami:")}
                    <span className="whitespan">{this.state.lostWeight}</span>
                  </p>
                </div>
                <div className="col-md-4">
                  <br />
                  <br />
                  <p className="white">
                    {i18next.t("Sliku koraka priložite ovde")}
                  </p>
                  <input
                    type="file"
                    name="file"
                    className="profileBtn"
                    onChange={(e) => this.handleFileKoraka(e)}
                  />
                  <br />
                  <br />
                  <button
                    onClick={this.fileUploadHandlerKoraka}
                    className="profileBtn"
                  >
                    {i18next.t("Posalji sliku koraka")}
                  </button>
                  <form onSubmit={(e) => this.daysSubmit(e)}>
                    <br />
                    <br />
                    <input
                      type="text"
                      placeholder={i18next.t("Broj koraka")}
                      onChange={(e) =>
                        this.setState({ numberOfSteps: e.target.value })
                      }
                    />
                    <br />
                    <br />
                    <input
                      type="text"
                      placeholder={i18next.t("Broj kilograma")}
                      onChange={(e) =>
                        this.setState({ weight: e.target.value })
                      }
                    />
                    <br />
                    <br />
                    <input
                      type="text"
                      placeholder={i18next.t("Obim struka")}
                      onChange={(e) =>
                        this.setState({ WaistSize: e.target.value })
                      }
                    />
                    <br />
                    <br />
                    <input
                      type="text"
                      placeholder={i18next.t("dobra stvar")}
                      onChange={(e) =>
                        this.setState({ goodDeed1: e.target.value })
                      }
                    />
                    <br />
                    <br />
                    <input
                      type="text"
                      placeholder={i18next.t("dobra stvar")}
                      onChange={(e) =>
                        this.setState({ goodDeed2: e.target.value })
                      }
                    />
                    <br />
                    <br />
                    <input
                      type="text"
                      placeholder={i18next.t("dobra stvar")}
                      onChange={(e) =>
                        this.setState({ goodDeed3: e.target.value })
                      }
                    />
                    <br />
                    <br />
                    <input
                      type="text"
                      placeholder={i18next.t("dobra stvar")}
                      onChange={(e) =>
                        this.setState({ goodDeed4: e.target.value })
                      }
                    />
                    <br />
                    <br />
                    <input
                      type="text"
                      placeholder={i18next.t("dobra stvar")}
                      onChange={(e) =>
                        this.setState({ goodDeed5: e.target.value })
                      }
                    />
                    <br />
                    <br />
                    <br />
                    <p className="intro text" id="usepsno"></p>
                    <br />

                    <section className="intro">
                      <button
                        id="js-trigger-overlay"
                        className="intro"
                        type="submit"
                      >
                        {i18next.t("postavi")}
                      </button>
                    </section>
                  </form>
                  <br />
                  <br />
                </div>
                <div className="col-md-4">
                  <br />
                  <br />
                  <p className="white">
                    {i18next.t("Sliku nedeljnog progresa prilozite ovde")}
                  </p>
                  <input
                    type="file"
                    name="file"
                    className="profileBtn"
                    onChange={(e) => this.handleFileProgresa(e)}
                  />
                  <br />
                  <br />
                  <button
                    onClick={this.fileUploadHandlerProgresa}
                    className="profileBtn"
                  >
                    {i18next.t("Posalji sliku progresa")}
                  </button>
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(Profile);
