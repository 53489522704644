import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import common_en from "./translations/eng.json";
import common_ger from "./translations/ger.json";
import common_ser from "./translations/ser.json";

const resources = {
  eng: {
    translation: common_en,
  },
  ger: {
    translation: common_ger,
  },
  ser: {
    translation: common_ser,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "ser",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
