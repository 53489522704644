import * as React from "react";
import { connect } from "react-redux";
import profilImage from "../img/profil.png";
import OneRecipe from "./smallComponents/OneRecipe";
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import OneBreakfast from "./smallComponents/OneBreakfast";
import "./i18n";
import i18next from "i18next";

const Breakfast = () => (
  <div>
    <div className="recepti">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2"></div>

          <div className="col-md-8">
            <br />
            <h1 className="text">{i18next.t("Recepti")}</h1>
            <br />
            <ul className="navbar-nav flex-grow">
              <NavLink tag={Link} className="nav center" to="/recepies">
                {i18next.t("Sve kategorije")}
              </NavLink>
              <NavLink tag={Link} className="nav center" to="/breakfast">
                {i18next.t("Dorucak")}
              </NavLink>
              <NavLink tag={Link} className="nav center" to="/lunch">
                {i18next.t("Rucak")}
              </NavLink>
              <NavLink tag={Link} className="nav center" to="/dinner">
                {i18next.t("Vecera")}
              </NavLink>
              <NavLink tag={Link} className="nav center" to="/drinks">
                {i18next.t("Napici")}
              </NavLink>
            </ul>
            <br />
            <OneBreakfast />
            <br />
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </div>
  </div>
);

export default connect()(Breakfast);
