import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Axios from "axios";

interface IKorisniciadmin {
  wishkg: any;
  values: any;
}

class Korisniciadmin extends React.PureComponent<{}, IKorisniciadmin> {
  state = {
    values: [],
    wishkg: "",
  };

  componentDidMount() {
    Axios.get("https://nutritionchangechallengebackend.com/api/Users/list").then(
      (response) => {
        this.setState({
          values: response.data,
        });
      }
    );
  }

  dodajUser(id: any): void {
    Axios({
      url: "https://nutritionchangechallengebackend.com/api/Programs/next",
      method: "GET",
      headers: {},
    }).then((res: any) => {
      Axios({
        url: "https://nutritionchangechallengebackend.com/api/Programs/AddUser",
        method: "POST",
        headers: {},
        data: {
          programId: res.data["id"],
          userId: id,
          wantedWeight: this.state.wishkg,
        },
      }).then((result: any) => {
        if (result.status === 200) {
          window.alert("Uspešno ste dodali korisnika!");
        }
      });
    });
  }

  deleteUser(id: any): void {
    var xhr = new XMLHttpRequest();
    var url = "https://nutritionchangechallengebackend.com/api/Users/" + id;
    alert(url);
    xhr.open("DELETE", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader(
      "Authorization",
      "Bearer " + localStorage.getItem("tokenUser")
    );

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        window.location.replace("/adminpanel");
      } else {
        //alert("ne radi");
      }
    };
    xhr.send(null);

    this.setState({});
  }

  public render() {
    return (
      <React.Fragment>
        <div>
          <div className="container-fluid prijava-profil">
            <div className="row">
              <div className="col-md-4"></div>

              <div className="col-md-4">
                <br />
                <h1 className="text">Svi korisnici</h1>
                <br />
                {this.state.values.map((value: any) => (
                  <div key={value.id}>
                    <br />
                    <div className="tema">
                      <h1
                        className="xright"
                        onClick={() => this.deleteUser(value.id)}
                      >
                        x
                      </h1>
                      <p className="text-forum">
                        <span className="pinkspan">Ime i prezime:&nbsp;</span>
                        {value.fullName}
                      </p>
                      <p className="text-forum">
                        <span className="pinkspan">Nadimak:&nbsp;</span>
                        {value.nickName}
                      </p>
                      <p className="text-forum">
                        <span className="pinkspan">Tezima u kg:&nbsp;</span>
                        {value.weight}
                      </p>
                      <p className="text-forum">
                        <span className="pinkspan">
                          Obim struka u cm:&nbsp;
                        </span>
                        {value.waistSize}
                      </p>
                      <br />
                      <Link
                        className="rightUser"
                        to={`/userdetails/${value.id}/${value.fullName}/${value.nickName}/${value.email}/${value.weight}`}
                        style={{ textDecoration: "none" }}
                        key={value.id}
                      >
                        saznaj vise &gt;
                      </Link>
                      <br />
                      <br />
                      <br />
                      <p className="text-forum">
                        <span className="pinkspan">
                          Željena kilaža korisnika:
                        </span>
                      </p>
                      <input
                        type="text"
                        id="wishkg"
                        name="wishkg"
                        onChange={(e) =>
                          this.setState({ wishkg: e.target.value })
                        }
                      />
                      <p
                        className="addOnProgram"
                        onClick={() => this.dodajUser(value.id)}
                      >
                        + Dodaj na novi program
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(Korisniciadmin);
