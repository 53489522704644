import * as React from "react";
import { connect } from "react-redux";
import profilImage from "../../img/profil.png";
import Axios from "axios";
import { Link } from "react-router-dom";

class TemaAdmin extends React.PureComponent {
  state = {
    values: [],
  };

  componentDidMount() {
    Axios.get(
      "https://nutritionchangechallengebackend.com/api/ForumTheme/list/Zdrav zivot"
    ).then((response) => {
      this.setState({ values: response.data });
    });
  }

  deleteTopic(id: any): void {
    var xhr = new XMLHttpRequest();
    var url = "https://nutritionchangechallengebackend.com/api/ForumTheme/" + id;
    //alert(url);
    xhr.open("DELETE", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        window.location.replace("/adminpanel");
      } else {
        //alert("ne radi");
      }
    };
    xhr.send(null);

    this.setState({});
  }

  public render() {
    return (
      <React.Fragment>
        <div>
          <div className="jedanrecept">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  {this.state.values.map((value: any, i: number) => (
                    <div key={value.id}>
                      <br />
                      <br />
                      <div className="row lista-jedan">
                        <div className="col-md-11">
                          <h2>{value.title}</h2>
                        </div>
                        <div className="col-md-1">
                          <h2
                            className="x"
                            onClick={() => this.deleteTopic(value.id)}
                          >
                            x
                          </h2>
                        </div>
                      </div>
                    </div>
                  ))}
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default connect()(TemaAdmin);
